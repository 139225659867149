import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@app/core/models/user';
import { ChangePassword } from "@app/authentication/change-password/models/change-password";
import { emailValidator } from '@app/shared/validators';
import { DialogsService } from '@app/core/services/dialogs.service';
import {UserService } from '@app/users/user.service'
import { AuthService } from "@app/core/services/auth.service";
import { PasswordChangeDialogComponent } from '../password-change-dialog/password-change-dialog.component';
import { MaintenanceList } from '@app/core/models/maintenanceList';
import { dateValidator2 } from '@app/shared/validators/validators';
import { PreventiveMaintenanceService } from '@app/preventiveMaintenance/preventiveMaintenance.service';
import { Files } from '@app/core/models/files';
import { CustomDateParserFormatter } from '@app/shared/dateFormatter';
import { DatePipe } from '@angular/common';
import { FileUploaderComponent } from '@app/shared/components/file-uploader/file-uploader.component';
import { FilesDialogComponent } from '../files-dialog/files-dialog.component';

@Component({
  selector: 'maintenanceList-dialog',
  templateUrl: './maintenanceList-dialog.component.html',
  styleUrls: ['./maintenanceList-dialog.component.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, 
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    DatePipe, FileUploaderComponent]
})
export class MaintenanceListDialogComponent implements OnInit {
    form: UntypedFormGroup;
    @Input() 
    maintenanceList!: MaintenanceList;
    file!: File;
   
    public isFailedForm:boolean = false;
    public fileUploadParams:any
    public fileList: Files[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private dialogsService: DialogsService,
        public filesDialogComponent: FilesDialogComponent,
        private preventiveMaintenanceService: PreventiveMaintenanceService) {
      
        this.form = this.fb.group({
            maintenanceListID: [0, []],
            propertyTrackingItemID: [0, []],
            propertyId: [0, []],
            propertyName: [{value: '', disabled: true}, []],
            trackingNameFrequencyName: [{value: '', disabled: true}, []],
            dueDate: [{value: ''}, []],
            dueOffset: [{value: 0, disabled: true}, []],
            backUpDocRequired: [false, []],
            backupDocFileID: [0, []],
            backupDocFileName: ['', []],
            backupDocFilePath: ['', []],
            maintenanceStatusID: [0, []],
            maintenanceStatusTypeName: [{value: '', disabled: true}, []],
            completedDate: ['', Validators.compose([Validators.required, dateValidator2])],
            attachedFileID: [0, []],
            attachedFileName: ['', []],
            attachedFilePath: ['', []],
            updatedById: [0, []],
            updatedByName: [{value: '', disabled: true}, []],
            updatedDate: [{value: '', disabled: true}, []],
            index: [9999, []],
        });
    }

    ngOnInit() {
        this.form.patchValue(this.maintenanceList);
        this.checkCompletedDate();
        this.checkDueDate();
      
    }

    save() {
       
        const formValue = this.form.getRawValue();
        const ml = Object.assign({}, this.maintenanceList, formValue);

        this.maintenanceList.completedDate = ml.completedDate;
        

      

        if(this.maintenanceList.backUpDocRequired === true){
          if(this.file || this.maintenanceList.backupDocFilePath != null) {
            //this.isAdd = false;
            this.preventiveMaintenanceService.updateMaintenanceList(this.maintenanceList).subscribe(_res => {
              this.fileUploadParams ={};
              this.fileUploadParams.panel = 'maintenanceList';
              this.fileUploadParams.propertyId = this.maintenanceList.propertyId;
              this.fileUploadParams.foreignId = this.maintenanceList.maintenanceListID;
              this.fileUploadParams.entity = 'cpp';
              if(this.maintenanceList.backupDocFileID > 0){
                this.fileUploadParams.fileId = this.maintenanceList.backupDocFileID;
              }
              //this.newMaintenanceListObject = valueItem;
             
  
              const title = 'Success';
              const message = `Maintenance list item succesfully saved.`;
              this.dialogsService.alert(title, message);
             // this.activeModal.close(this.maintenanceList);
             
            })
          } else {
            if(this.maintenanceList.backupDocFilePath != null){
              this.preventiveMaintenanceService.updateMaintenanceList(this.maintenanceList).subscribe(_res => {
                
  
                const title = 'Success';
                const message = `Maintenance list item successfully saved.`;
                this.dialogsService.alert(title, message);
                this.activeModal.close(this.maintenanceList);
              });
            } else {
              this.dialogsService.alert('error', 'A backup document file must be uploaded.')
            }
          }
        } else {
          //this.isAdd = false;
          this.preventiveMaintenanceService.updateMaintenanceList(this.maintenanceList).subscribe(_res => {
            
  
            const title = 'Success';
            const message = `Maintenance list item succesfully saved.`;
            this.dialogsService.alert(title, message);
            this.activeModal.close(this.maintenanceList);
          })
         
        }

     
    }

   

    close() {
        this.activeModal.dismiss();
    }

    

    showConfirmationMessage() {
        const title = 'Confirmation';
        const message = 'This form will not be saved, are you sure you want to exit this form?';
        return this.dialogsService.confirmation(title, message)
    }

    public sendIndex(){
       // this.maintenanceList.index = 1;
      
      }
    
      public indexChangedAfterUpload(event: any){
        console.log('%c event index', 'background: yellow; color: black', event);
        // item.addControl('index', new FormControl('', Validators.required));
        // item.controls['index'].setValue(event);
        
    
      }
      public closeModal(){
        //his.trackingItems = [];
        //this.activeModal.dismiss();
        //this.eventCloseModal.emit({});
      }
    
    
      public successFileUpload(file: any) {
        this.file = file;
       
      }
    
      public checkDueDate(){
        var formValue = this.form.value;
        if(formValue.dueDate){
          formValue.dueDate = new Date(formValue.dueDate);
          if(!this.isValidDate(formValue.dueDate)){
            formValue.dueDate = null;
          }
          this.form.patchValue(formValue);
        }
      }
      public checkCompletedDate(){
        var formValue = this.form.value;
        if(formValue.completedDate){
          formValue.completedDate = new Date(formValue.completedDate);
          if(!this.isValidDate(formValue.completedDate)){
            formValue.completedDate = null;
          }
          this.form.patchValue(formValue);
        }
      }
    
    
      private isValidDate(d: any) {
        var returnValue: boolean = false;
        if (Object.prototype.toString.call(d) === "[object Date]") {
          if (isNaN(d.getTime())) { 
            returnValue = false;
          } else {
            returnValue = true;
          }
        } else {
          returnValue = false;
        }
        return returnValue;
      }
    
}
