import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct = {year:0, month:0, day:0};
    if (value) {
      let date = value.split(this.DELIMITER);
      result = {
        day : parseInt(date[1], 10),
        month : parseInt(date[0], 10),
        year : parseInt(date[2], 10)
      };
    }
    console.log('TEST')
    return result;
  }

  format(date: NgbDateStruct): string {
    let result: string = '';
    if (date) {
      result = date.month + this.DELIMITER + date.day + this.DELIMITER + date.year;
    }
    return result;
  }
}