import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AgGridEvent, GridApi, ColumnApi, ColDef, RowClickedEvent, FilterChangedEvent, GridOptions, Column } from "ag-grid-community";
import { ManagementGridActionComponent } from '../management-grid-action/management-grid-action.component';
import { ManagementGridService } from './management-grid.service';
import { UserGridColumn, AuthService } from '@app/core';
import { DatePipe } from '@angular/common';
import { tap } from 'rxjs/operators';
import {ButtonRendererComponent} from "@app/core/component/buttonRenderer/buttonRenderer.component"
import { PropertyService } from '@app/properties/properties.service';

@Component({
  selector: 'management-grid',
  templateUrl: './management-grid.component.html',
  styleUrls: ['./management-grid.component.css'],
  providers: [DatePipe]
})
export class ManagementGridComponent implements OnInit {

  initialRowDataLoad$: any;
  rowDataUpdates$: any;

  private gridApi!: GridApi;
  private gridColumnApi!: ColumnApi;
  public userEntity: string[] | null = [];


  public defaultColDef = {
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true
  }

  @Output('onReady')
  private onGridReadyEmitter = new EventEmitter();
  @Output('onClick')
  private onRowClickEmitter = new EventEmitter();
  @Output('onAction')
  private onRowActionEmitter = new EventEmitter();
  @Output('propertyList')
  private onPropertyListEmitter = new EventEmitter();
  @Output('companyList')
  private onCompanyListEmitter = new EventEmitter();
  @Output('userList')
  private onUserListEmitter = new EventEmitter();


  @Input('columns')
  public columnDefs: ColDef[] = [];
  @Input('data')
  public rowData: [] = [];
  @Input('gridName')
  public gridName: string = '';
  @Input('enableExport')
  public enableExport: boolean = false;

  public gridOptions: GridOptions;

  public gridColumns: {
    colId: string,
    headerName: string,
    visible: boolean
  }[] = [];
  public context: any;
  public frameworkComponents: any;
  public hasFilters: boolean = false;
  private savedColumns: UserGridColumn[] = [];
  public user: boolean = false;
  public company: boolean = false;
  public property: boolean = false;
  public units: boolean = false;
  public userList: string | null | undefined;
  public companyList: string | null | undefined;
  public propertyList: string | null | undefined;


  constructor(
    private managementGridService: ManagementGridService,
    private propertyService: PropertyService,
    private authService: AuthService,
    private datePipe: DatePipe) {
    this.gridOptions = {
      paginationAutoPageSize: true,
      columnDefs:[
        {
          headerName:"Building",
          children:[
            { headerName: 'Building Number', field: 'buildingNumberUnits'},
            { headerName: 'BIN', field: 'binUnits'},
            { headerName: 'Unit Number', field: 'unitNumber', width: 200, },
            {
              headerName: 'Vacant Unit', field: 'vacantUnit', width: 225,
              cellRenderer: params => params.value ? 'Yes' : 'No' },
            { headerName: 'First Year Tenant Name', field: 'firstYearTenantName', width: 175 },
            
            { headerName: 'Created By Name', field: 'createdByName', width: 150 },
            { headerName: 'Updated By Name', field: 'updatedByName', width: 150 },
        
        
            //{ headerName: '', cellRenderer: 'actions', pinned: 'right', width: 90 }
          ]
        }
      ]
    }
    this.context = {
      componentParent: this
    };
    this.frameworkComponents = {
      actions: ManagementGridActionComponent,
      buttonRenderer: ButtonRendererComponent
    }
  }

  ngOnInit() {
    this.userEntity = this.authService.authUserEntity();
    //BFS: 1.04 - this.gridName is just the name of the page you are on (which can be found in _________)
    if (this.gridName === 'properties') {
      //BFS: 1.04 - this shows the radio buttons for the properties page
      this.property = true;
      if(this.userEntity){
        if(this.userEntity.indexOf('wnc') === 0){
          this.propertyList = 'wnc';
        }
        if(this.userEntity.indexOf('cpp') === 0){
          this.propertyList = 'cpp';
        }
        if(this.userEntity.indexOf('pef') === 0){
          this.propertyList = 'pef';
        }
        if(this.propertyList !== 'wnc'){
          if (this.propertyList) {
            this.propertyListChange(this.propertyList);
          }
        }
      }
      //BFS: 1.04 - this.propertyList is now 'WNC'
      //this.propertyList = this.userEntity;
      //BFS: 1.04 - if the propertyList is not WNC it'll go in and change the radio button to whatever your entity is
      // if (this.propertyList !== 'WNC') {
      //   if (this.propertyList) {
      //     this.propertyListChange(this.propertyList);
      //   }
      // }
    }

    if (this.gridName === 'companies') {
      //BFS: 1.04 - this shows the radio buttons for the companies page
      this.company = true;
      //BFS: 1.04 - this.companyList is now 'WNC' or whatever ur entity is set to
      //this.companyList = this.userEntity;
      if(this.userEntity){
        if(this.userEntity.indexOf('wnc') === 0){
          //set the this.userList (the pink dot) as WNC
          this.companyList = 'wnc';
        } 
        if(this.userEntity.indexOf('cpp') === 0){
          //set the this.userList (the pink dot) as WNC
          this.companyList = 'cpp';
        }
        if(this.userEntity.indexOf('pef') === 0){
          //set the this.userList (the pink dot) as WNC
          this.companyList = 'pef';
        }
        if(this.companyList !== 'wnc'){
          if (this.companyList) {
            this.companyListChange(this.companyList);
          }
        }
      }
    }

    if(this.gridName === 'users'){
      this.user = true;
      //BFS: 1.10.4 - if you are wnc then the first index of will be wnc
      //if you are both wnc and cpp, the 0 index will be wnc and if you're just cpp, then index of 0 will b cpp
      //so if you are wnc entity
      if(this.userEntity){
        if(this.userEntity.indexOf('wnc') === 0){
          //set the this.userList (the pink dot) as WNC
          this.userList = 'wnc';
        }
        if(this.userEntity.indexOf('cpp') === 0){
          //set the this.userList (the pink dot) as WNC
          this.userList = 'cpp';
        }
        if(this.userEntity.indexOf('pef') === 0){
          //set the this.userList (the pink dot) as WNC
          this.userList = 'pef';
        }
        if(this.userList !== 'wnc'){
          console.log('%c comes in if this.userList in grid is not wnc', 'background: yellow; color: black', );
          if (this.userList) {
            this.userListChange(this.userList);
          }
        }
      }
    }

    if(this.gridName === 'unitDetails'){
      this.units = true;

    }
  }

  onGridReady(params: AgGridEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadRemoteColumnsState()
      .subscribe(() => {
        this.updateColumsMenu();
      });

    this.onGridReadyEmitter.emit();
  }

  onRowClicked(params: RowClickedEvent) {
    this.onRowClickEmitter.emit(params.data);
  }

  onAction(actionType: string, data: any) {
    this.onRowActionEmitter.emit({ actionType: actionType, data: data });
  }

  onFilterChanged(params: FilterChangedEvent) {
    const filterModel = params.api.getFilterModel();
    this.hasFilters = Object.keys(filterModel).length > 0;
  }

  onColumnVisible(_e: any) {
    //console.log('%c management-grid.component', 'background: yellow; color: black', e);
  }

  private updateColumsMenu() {

    this.gridColumns = this.gridColumnApi.getAllColumns()
      .map(column => {
        return {
          colId: column.getColId(),
          visible: column.isVisible(),
          headerName: column.getColDef().headerName || ''
        }
      })
      //this is where it filters out the column header names so that it gets repeated on the left side check box table columns
      .filter(column => column.headerName);
  }

  toggleColumn(colId: string) {
    const col = this.gridColumns.find(c => c.colId === colId);
    if (col) {
      this.gridColumnApi.setColumnVisible(colId, !col.visible);
      this.updateColumsMenu();
      this.saveRemoteColumnsState(col);
    }
  }

  exportGrid() {
    const params = {
      fileName: `${this.gridName}_${this.datePipe.transform(new Date(), 'MM_dd_yyyy')}`,
      processCellCallback: (params: any) => {
        if (params.value instanceof Date) {
          return this.datePipe.transform(params.value, 'MM/dd/yyyy') || ''
        } 
        else if ( params.column.colId === "moveInDate" || params.column.colId === "certEffectDate" 
                  || params.column.colId === "tempDateVacated" || params.column.colId === "tempDateReturned"
                  || params.column.colId === "moveOutDate" || params.column.colId === "previousCertEffectDate") 
        { 
          return this.datePipe.transform(params.value, 'MM/dd/yyyy') || ''
        }
        else if (typeof params.value === "boolean") {
          return params.value ? 'Yes' : 'No'
        }
        return params.value;
      }
    }
    this.gridApi.exportDataAsCsv(params);
  }

  private loadRemoteColumnsState() {
    return this.managementGridService.getGridColumns(this.gridName)
      .pipe(
        tap((data) => {
          this.savedColumns = data;
          this.gridColumnApi.getAllColumns()
            .forEach(column => {
              const savedCol = this.savedColumns.find(c => c.columnName === column.getColId());
              this.gridColumnApi.setColumnVisible(column.getColId(), savedCol ? savedCol.visibility : true && !column.getColDef().hide);
            });
        })
      );
  }

  private saveRemoteColumnsState(col: any) {
    const model: UserGridColumn = {
      id: 0,
      gridName: this.gridName,
      columnName: col.colId,
      visibility: !col.visible
    };
    this.managementGridService.updateGridColumns(model)
      .subscribe(() => {
        //this.loadRemoteColumnsState();
      });
  }

  clearFilters() {
    this.gridApi.setFilterModel({});
  }

  selectAllDeselectCloumns() {
    //this.gridOptions.columnApi
    if(this.gridColumns){
      //const uncheckedExists = this.gridColumns.some(el => el.visible === false);
      const checkedExists = this.gridColumns.some(el => el.visible === true);

      //if checked exists UNCHECK everything that is true
      if(checkedExists === true){
        for (let j = 0; j < this.gridColumns.length; j++) {
          if(this.gridColumns[j].visible === true){
            this.toggleColumn(this.gridColumns[j].colId);
          }
        }
      } else {
        for (let j = 0; j < this.gridColumns.length; j++) {
          if(this.gridColumns[j].visible === false){
            this.toggleColumn(this.gridColumns[j].colId);
          }
        }
      }
    }
  }

  public propertyListChange(actionType: string) {
    //BFS: 1.04 - the actionType changes as you click the different radio button for either wnc cpp or pef properties to filter through
    return this.onPropertyListEmitter.emit({ actionType: actionType })
  }

  public companyListChange(actionType: string) {
    //BFS: 1.04 - the actionType changes as you click the different radio button for either wnc cpp or pef companies to filter through
    return this.onCompanyListEmitter.emit({ actionType: actionType })
  }

  public userListChange(actionType: string) {
    return this.onUserListEmitter.emit({ actionType: actionType })
  }

}
