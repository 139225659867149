<div class="modal-header">
    <h4 class="modal-title">Update Maintenance Task</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="form">

        <div class="form-row">  
            <div class="form-group required col-md-6">
               <label class="control-label">Property Name:</label>
                <input class="form-control" formControlName="propertyName"  />
              
            </div>
            
            <div class="form-group required col-md-6">
               <label class="control-label">Tracking/Freq:</label>
                <input class="form-control" formControlName="trackingNameFrequencyName"  />
                
            </div>

            <div class="form-group required col-md-6">
               <label class="control-label">Due Date:</label>
               <input class="form-control" (click)="d1.toggle()" formControlName="dueDate" name="dp" ngbDatepicker #d1="ngbDatepicker"
               [minDate]="{year: 1900, month: 1, day: 1}"
               [maxDate]="{year: 2099, month: 12, day: 31}"
               (blur)="checkDueDate();"  [readonly]="true">
            </div>

            <div class="form-group col-md-6">
               <label class="control-label">Days Overdue:</label>
               <input type="text" class="form-control text-right-align" formControlName="dueOffset">
            </div>
            <div class="form-group col-md-6">
                <label class="control-label">Completed On:</label>
                <input class="form-control" formControlName="completedDate" name="dp" ngbDatepicker #d="ngbDatepicker"
                                              [minDate]="{year: 1900, month: 1, day: 1}"
                                              [maxDate]="{year: 2099, month: 12, day: 31}"
                                              (blur)="checkCompletedDate();">
                                              <div class="input-group-append">
                                                  <button style="height: 38px !important;" class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                              </div>
             </div>
             <div class="form-group col-md-6" *ngIf="maintenanceList.backUpDocRequired === true" (click)="sendIndex()">
                <label class="control-label">Backup Document:</label>
                <file-uploader (valueChange)='successFileUpload($event)' [params]="fileUploadParams"  class="" [entity]="'cpp'"
                (closeModal)="closeModal()" [url]="maintenanceList.backupDocFilePath" [fileName]="maintenanceList.backupDocFileName" 
                (indexChange)="indexChangedAfterUpload($event)" [sendingIndex]="maintenanceList.index"> 
                  <div class="file-uploader-box" *ngIf ="fileList.length">
                    <div class="file-uploader-file" *ngFor="let item of fileList">
                      <div class="name-file">
                        <span>{{ item.name }}</span>
                      </div>
                      <div class="file-uploader-buttons">
                        <button class="btn btn-light btn-sm" title="Download" (click)="filesDialogComponent.getDownloadURL(item.id, item.name)">
                          <i class="fa fa-download"  aria-hidden="true" *ngIf= "!filesDialogComponent.isDownloading[item.id]"></i>
                          <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf= "filesDialogComponent.isDownloading[item.id]"></i>
                        </button>
                        <button class="btn btn-danger btn-sm" title="Delete" (click)="filesDialogComponent.deleteFile(item.id)" [disabled]="!filesDialogComponent.canDelete">
                          <i class="fa fa-trash" aria-hidden="true" *ngIf= "!filesDialogComponent.isDeleting[item.id]"></i>
                          <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf= "filesDialogComponent.isDeleting[item.id]"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </file-uploader>
             </div>
        </div>

    </form>
    

</div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
      <button type="button" class="btn btn-primary" [disabled] = "(maintenanceList.completedDate != null && maintenanceList.backUpDocRequired  === false) ||
      (maintenanceList.completedDate  != null && (maintenanceList.backUpDocRequired === true && maintenanceList.backupDocFilePath != null)) ||
      (maintenanceList.completedDate != null )" (click)="save()">Save changes</button>
    </div>