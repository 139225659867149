<div class="modal-header">
  <h4 class="modal-title col-md-9">{{ dialogTitle }}</h4>
  <!--<button *ngIf="!form3.controls['unitLocked'].value && (isAdmin)"  type="button" class="btn col-md-1 lock-unlock-button" (click)="lock()">
    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
  </button>
  <button *ngIf="form3.controls['unitLocked'].value && (isAdmin)"  type="button" class="btn col-md-1 lock-unlock-button" (click)="unlock()">
    <i class="fa fa-lock" aria-hidden="true"></i>
  </button>-->
  <button type="button" class="btn col-md-2 notify-button-unit" (click)="openNotifyReason()" *ngIf="(form3.controls['unitLocked'].value && (!isAdmin))">
    NOTIFY
  </button>
  <button type="button" class="close col-md-1 close-margin" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- IF ITS NOT EXEMPT AND NOT MARKET SO... EITHER VACANT? OR NORMAL -->
<div class="modal-body" *ngIf="formType != 'Exempt' && formType != 'Market'">
  <form [formGroup]="formExemptTop" class="no-highlight">
    <div class="form-row col-md-12">
      <div class="form-group col-md-3" *ngIf="formType == 'Normal' || formType == 'NormalPrevQual' || formType == 'Exempt'">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="exemptUnit" type="checkbox" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="exemptUnit" (ngModelChange)="exemptUnitChecked()" />
            <label class="custom-control-label d-block" for="exemptUnit">Exempt Unit<i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="exemptUnitPopover"
              triggers="mouseenter:mouseleave" placement="bottom"></i></label>
          </div>
      </div>
      <!-- ONLY FOR NORMAL OR MARKET -->
      <div class="form-group col-md-2" *ngIf="formType == 'Normal' || formType == 'NormalPrevQual' || formType == 'Market'">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="marketUnit" type="checkbox"  [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="marketUnit" (ngModelChange)="marketUnitChecked()" />
            <label class="custom-control-label d-block" for="marketUnit">Market Unit</label>
          </div>
      </div>
      <!-- FOR EDIT: SHOW TENANT TRANSER CHECK BOX -->
      <div class="form-group col-md-3" *ngIf="(formType == 'Normal' || formType == 'NormalPrevQual' || formType == 'Transfer' || formType == 'TransferPrevQual') && !addUnit">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="tenantTransfer" type="checkbox"  [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="tenantTransfer" (ngModelChange)="tenantTransferChecked()" />
            <label class="custom-control-label d-block" for="tenantTransfer">Tenant Transfer:<i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="tenantTransferPopover"
              triggers="mouseenter:mouseleave" placement="bottom"></i></label>
          </div>
      </div>
      <!-- FOR EDIT: SHOW VACANT UNIT CHECK BOX -->
      <div class="form-group col-md-1" *ngIf="((formType == 'Normal' || formType == 'Vacant') && addUnit) || (formType =='Vacant') && !addUnit">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="tenantTransfer" type="checkbox"  [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="vacantUnit" (ngModelChange)="vacantUnitChecked()" />
            <label class="custom-control-label d-block" for="tenantTransfer">Vacant Unit:</label>
          </div>
      </div>
      <!-- FOR EDIT: SHOW NON QUALIFIED OCCUPIED UNIT CHECK BOX -->
      <div class="form-group col-md-3" *ngIf="((formType == 'Normal' || formType == 'NonQualifiedOccupied') && addUnit) || (formType =='NonQualifiedOccupied') && !addUnit">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="nonQualifiedOccupied" type="checkbox"  [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="nonQualifiedOccupied" (ngModelChange)="nonQualifiedOccupiedChecked()" />
            <label class="custom-control-label d-block" for="nonQualifiedOccupied">Non-Qual. Occu.:<i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="nonQualifiedOccupiedPopover"
              triggers="mouseenter:mouseleave" placement="bottom"></i></label>
          </div>
      </div>
    </div>
    <div class="form-group col-md-12" *ngIf="isAdmin">
      <label class="control-label">Internal Comment</label>
      <textarea style="resize: none;" rows="4" placeholder="Internal Comment" class="form-control" formControlName="internalComment" type="text"></textarea>
    </div>
  </form>   
  <!-- FOR EDIT: WHEN YOU CLICK TENANT TRANSFER -->
  <form [formGroup]="formTenantTransfer" class="no-highlight" *ngIf="formType == 'Transfer' || formType == 'TransferPrevQual'">
    <div class="form-row" *ngIf="isAdmin">
      <!-- BFS: adding 3 new fields 1.08 11.11.2020 -->
      <div class="form-group col-md-2">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="unitFileReceived" type="checkbox" formControlName="unitFileReceived"/>
          <label class="custom-control-label d-block" for="unitFileReceived">File Rcvd:</label>
        </div>
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="unitCorrectionReceived" type="checkbox" formControlName="unitCorrectionReceived"/>
          <label class="custom-control-label d-block" for="unitCorrectionReceived">Correction Rcvd:</label>
        </div>
        <div class="col-md-12" style="padding-left: 0px; padding-right: 0px; top: 15px;">
          <label class="control-label">File Rating:</label>
          <select class="form-control" formControlName="fileRatingID">
            <option value="">-</option>
            <option *ngFor="let frType of fileRatingTypes" [ngValue]="frType.fileRatingID">{{frType.fileRatingName}}</option>
          </select>
        </div>
      </div>
      <div class="form-group col-md-10">
          <label class="control-label">Unit Comments:</label>
          <textarea style="resize: none;" rows="4" placeholder="Unit Comments" class="form-control" formControlName="unitComments" type="text"></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">New Unit #:</label>
        <input class="form-control" (ngModelChange)="tenantTransferFormValidation()" type="text" formControlName="newUnitNumber" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
                [class.error]="(isFailedForm ||formTenantTransfer.get('newUnitNumber')!.touched) && (formTenantTransfer.controls['newUnitNumber'].value === null || formTenantTransfer.controls['newUnitNumber'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('newUnitNumber')!.touched) && (formTenantTransfer.controls['newUnitNumber'].value === null || formTenantTransfer.controls['newUnitNumber'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Tenant Transfer Date:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="dateOfTransferPopover"
                                                                        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input readonly class="form-control" (click)="d1.toggle()" (ngModelChange)="tenantTransferFormValidation()" formControlName="dateOfTransfer" name="dp" [disabled]="form3.controls['unitLocked'].value"
                  [class.error]="(isFailedForm ||formTenantTransfer.get('dateOfTransfer')!.touched) && (formTenantTransfer.get('dateOfTransfer')!.hasError('dateInvalid') || (formTenantTransfer.controls['dateOfTransfer'].value === null || formTenantTransfer.controls['dateOfTransfer'].value === ''))"
                  [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                  ngbDatepicker #d1="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button" [disabled]="form3.controls['unitLocked'].value"></button>
          </div>
        </div>
        <span class="error-text"
          *ngIf="(isFailedForm ||formTenantTransfer.get('dateOfTransfer')!.touched) && (formTenantTransfer.controls['dateOfTransfer'].value === null || formTenantTransfer.controls['dateOfTransfer'].value === '')">
          This field is required
        </span>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('dateOfTransfer')!.touched) && formTenantTransfer.get('dateOfTransfer')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-3 form-group required" style="padding-bottom: 5px;">
        <label class="control-label">Building:</label>
        <select class="form-control" formControlName="buildingID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option *ngFor="let building of buildings" [ngValue]="building.buildingID">{{building.buildingNumber}}</option>
        </select>
      </div>
      <div class="col-md-3 form-group" style="padding-bottom: 5px;" *ngIf="isAdmin">
        <label class="control-label">Qualification Override:</label>
        <select class="form-control" formControlName="qualificationOverride">
          <option value=""></option>
          <option value="Include">Include</option>
          <option value="Exclude">Exclude</option>
        </select>
      </div>

    </div>
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">First Year Tenant Name:</label><i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="firstYearTenantPopover"
                                                                      triggers="mouseenter:mouseleave" placement="bottom"></i>
        <input class="form-control" placeholder="Last, First" pattern="[-A-Za-z-]+(\, ){1}[-A-Za-z-]+" formControlName="firstYearTenantName" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
            [class.error]="(isFailedForm ||formTenantTransfer.get('firstYearTenantName')!.touched) && formTenantTransfer.controls['firstYearTenantName'].value === ''"  />
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('firstYearTenantName')!.touched) && formTenantTransfer.controls['firstYearTenantName'].value === ''">
          This field is required
        </span>
        <span class="error-text"
        *ngIf="(formTenantTransfer.controls['firstYearTenantName'].value !== '') && formTenantTransfer.get('firstYearTenantName')!.hasError('pattern')">
            Invalid Name Format (Last, First)
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Unit #:</label>
        <input class="form-control" (ngModelChange)="_updateCalculations()" type="text" formControlName="unitNumber" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('unitNumber')!.touched) && (formTenantTransfer.controls['unitNumber'].value === null || formTenantTransfer.controls['unitNumber'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('unitNumber')!.touched) && (formTenantTransfer.controls['unitNumber'].value === null || formTenantTransfer.controls['unitNumber'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Move-In Date:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopover"
                                                                      triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (click)="d10.toggle()" (ngModelChange)="_updateMoveOutLimit()" formControlName="moveInDate" name="dp" [disabled]="form3.controls['unitLocked'].value"
                [class.error]="(isFailedForm ||formTenantTransfer.get('moveInDate')!.touched) && (formTenantTransfer.get('moveInDate')!.hasError('dateInvalid') || (formTenantTransfer.controls['moveInDate'].value === null || formTenantTransfer.controls['moveInDate'].value === ''))"
                [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                ngbDatepicker  #d10="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d10.toggle()" type="button" [disabled]="form3.controls['unitLocked'].value"></button>
          </div>
        </div>
        <span class="error-text"
        *ngIf="(isFailedForm ||formTenantTransfer.get('moveInDate')!.touched) && (formTenantTransfer.controls['moveInDate'].value === null || formTenantTransfer.controls['moveInDate'].value === '')">
    This field is required
  </span>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('moveInDate')!.touched) && formTenantTransfer.get('moveInDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Move-Out Date:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopoverMoveOut"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (click)="d2.toggle()" formControlName="moveOutDate" name="dp" [disabled]="form3.controls['unitLocked'].value"
                [class.error]="formTenantTransfer.get('moveOutDate')!.hasError('dateInvalid')"
                [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                ngbDatepicker [minDate]="minEndDate" #d2="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" [disabled]="form3.controls['unitLocked'].value" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('moveOutDate')!.touched) && formTenantTransfer.get('moveOutDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
    </div>
    <hr>
    <!--row 2 startdate and enddate-->
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">Certification Effect Date:</label><i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="certEffDatePopover"
                                                                          triggers="mouseenter:mouseleave" placement="top"></i>
        <div class="input-group">
          <input class="form-control" (click)="d3.toggle()"  formControlName="certEffectDate" name="dp" [disabled]="form3.controls['unitLocked'].value"
          [class.error]="(isFailedForm ||formTenantTransfer.get('certEffectDate')!.touched) && (formTenantTransfer.get('certEffectDate')!.hasError('dateInvalid') || (formTenantTransfer.controls['certEffectDate'].value === null || formTenantTransfer.controls['certEffectDate'].value === ''))"
          [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
          ngbDatepicker #d3="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" [disabled]="form3.controls['unitLocked'].value" (click)="d3.toggle()" type="button"></button>
          </div>
        </div>
        <span class="error-text"
        *ngIf="(isFailedForm ||formTenantTransfer.get('certEffectDate')!.touched) && (formTenantTransfer.controls['certEffectDate'].value === null || formTenantTransfer.controls['certEffectDate'].value === '')">
    This field is required
  </span>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('certEffectDate')!.touched) && formTenantTransfer.get('certEffectDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Temporary Date Vacated:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopoverMoveOut"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" formControlName="tempDateVacated" (click)="d4.toggle()" name="dp" [disabled]="form3.controls['unitLocked'].value"
                [class.error]="formTenantTransfer.get('tempDateVacated')!.hasError('dateInvalid')"
                [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                ngbDatepicker #d4="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" [disabled]="form3.controls['unitLocked'].value" (click)="d4.toggle()" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('tempDateVacated')!.touched) && formTenantTransfer.get('tempDateVacated')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Temporary Date Returned:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopoverMoveOut"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (click)="d5.toggle()" formControlName="tempDateReturned" name="dp" [disabled]="form3.controls['unitLocked'].value"
                [class.error]="formTenantTransfer.get('tempDateReturned')!.hasError('dateInvalid')"
                [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                ngbDatepicker #d5="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" [disabled]="form3.controls['unitLocked'].value" (click)="d5.toggle()" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('tempDateReturned')!.touched) && formTenantTransfer.get('tempDateReturned')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <div>
          <label class="control-label">Unit Set-Aside:</label>
        </div>
        <input class="form-control" formControlName="unitSetAside" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('unitSetAside')!.touched) && (formTenantTransfer.controls['unitSetAside'].value === null || formTenantTransfer.controls['unitSetAside'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('unitSetAside')!.touched) && (formTenantTransfer.controls['unitSetAside'].value === null || formTenantTransfer.controls['unitSetAside'].value === '')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formTenantTransfer.get('unitSetAside')!.touched) && formTenantTransfer.get('unitSetAside')!.hasError('min')">
          Set Aside Value cannot be negative or zero
        </span>
      </div>
    </div>
    <hr>
    <!--row 3 the Units-->
    <div class="form-row">
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label">Square Feet:</label>
        </div>
        <input class="form-control" type="number" formControlName="squareFeet" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('squareFeet')!.touched) && (formTenantTransfer.controls['squareFeet'].value === null || formTenantTransfer.controls['squareFeet'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('squareFeet')!.touched) && (formTenantTransfer.controls['squareFeet'].value === null || formTenantTransfer.controls['squareFeet'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Bedrooms:</label>
        </div>
        <input class="form-control" type="number" formControlName="numOfBdrms" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('numOfBdrms')!.touched) && (formTenantTransfer.controls['numOfBdrms'].value === null || formTenantTransfer.controls['numOfBdrms'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('numOfBdrms')!.touched) && (formTenantTransfer.controls['numOfBdrms'].value === null || formTenantTransfer.controls['numOfBdrms'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-8">

      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label">Total # of Household:</label>
        </div>
      <input class="form-control" type="number"  min="1" formControlName="totalTenants" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('totalTenants')!.touched) && (formTenantTransfer.controls['totalTenants'].value === null || formTenantTransfer.controls['totalTenants'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('totalTenants')!.touched) && (formTenantTransfer.controls['totalTenants'].value === null || formTenantTransfer.controls['totalTenants'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Minors:</label>
        </div>
        <input class="form-control" type="number" formControlName="numOfMinors" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('numOfMinors')!.touched) && (formTenantTransfer.controls['numOfMinors'].value === null || formTenantTransfer.controls['numOfMinors'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('numOfMinors')!.touched) && (formTenantTransfer.controls['numOfMinors'].value === null || formTenantTransfer.controls['numOfMinors'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Seniors:</label>        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="numOfSeniorsPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" type="number" formControlName="numOfSeniors" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('numOfSeniors')!.touched) && (formTenantTransfer.controls['numOfSeniors'].value === null || formTenantTransfer.controls['numOfSeniors'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('numOfSeniors')!.touched) && (formTenantTransfer.controls['numOfSeniors'].value === null || formTenantTransfer.controls['numOfSeniors'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Veterans:</label>
        </div>
        <input class="form-control" type="number" formControlName="numOfVeterans" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('numOfVeterans')!.touched) && (formTenantTransfer.controls['numOfVeterans'].value === null || formTenantTransfer.controls['numOfVeterans'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('numOfVeterans')!.touched) && (formTenantTransfer.controls['numOfVeterans'].value === null || formTenantTransfer.controls['numOfVeterans'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Students:</label>
        </div>
        <input class="form-control" type="number" formControlName="numOfStudents" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('numOfStudents')!.touched) && (formTenantTransfer.controls['numOfStudents'].value === null || formTenantTransfer.controls['numOfStudents'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('numOfStudents')!.touched) && (formTenantTransfer.controls['numOfStudents'].value === null || formTenantTransfer.controls['numOfStudents'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="allFullTimeStudents" type="checkbox" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="allFullTimeStudents" />
          <label class="custom-control-label d-block" for="allFullTimeStudents">All Full-Time Students:</label>
        </div>
      </div>
    </div>
    <hr>
    <!--row 4 vacant-->
    <div class="form-row">
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Gross Income at Move-In:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateTenantTransferCalculations()" type="number" formControlName="grossIncome" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('grossIncome')!.touched) && (formTenantTransfer.controls['grossIncome'].value === null || formTenantTransfer.controls['grossIncome'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('grossIncome')!.touched) && (formTenantTransfer.controls['grossIncome'].value === null || formTenantTransfer.controls['grossIncome'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Income Limits at Move-In:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateTenantTransferCalculations()" formControlName="moveInIncomeLimits" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('moveInIncomeLimits')!.touched) && (formTenantTransfer.controls['moveInIncomeLimits'].value === null || formTenantTransfer.controls['moveInIncomeLimits'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('moveInIncomeLimits')!.touched) && (formTenantTransfer.controls['moveInIncomeLimits'].value === null || formTenantTransfer.controls['moveInIncomeLimits'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Income Qualified:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="TenIncomeQualifiedPopover"
                                                                                            triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <input class="form-control" id='tenantIncomeQualified' disabled type="text" />
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Tenant Payment:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateTenantTransferCalculations()" formControlName="unitRent" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('unitRent')!.touched) && (formTenantTransfer.controls['unitRent'].value === null || formTenantTransfer.controls['unitRent'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('unitRent')!.touched) && (formTenantTransfer.controls['unitRent'].value === null || formTenantTransfer.controls['unitRent'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Rent Subsidy:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="rentSubsidyPopover"
          triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <input class="form-control" (ngModelChange)="_updateTenantTransferCalculations()" formControlName="rentSubsidy" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('rentSubsidy')!.touched) && (formTenantTransfer.controls['rentSubsidy'].value === null || formTenantTransfer.controls['rentSubsidy'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('rentSubsidy')!.touched) && (formTenantTransfer.controls['rentSubsidy'].value === null || formTenantTransfer.controls['rentSubsidy'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">U/A:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateTenantTransferCalculations()" formControlName="utilityAllowance" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('utilityAllowance')!.touched) && (formTenantTransfer.controls['utilityAllowance'].value === null || formTenantTransfer.controls['utilityAllowance'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('utilityAllowance')!.touched) && (formTenantTransfer.controls['utilityAllowance'].value === null || formTenantTransfer.controls['utilityAllowance'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Non Optional Fee:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="nonOptionalFeePopover"
          triggers="mouseenter:mouseleave" placement="right"></i>

        </div>
        <input class="form-control" (ngModelChange)="_updateTenantTransferCalculations()" formControlName="nonOptionalFee" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formTenantTransfer.get('nonOptionalFee')!.touched) && (formTenantTransfer.controls['nonOptionalFee'].value === null || formTenantTransfer.controls['nonOptionalFee'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('nonOptionalFee')!.touched) && (formTenantTransfer.controls['nonOptionalFee'].value === null || formTenantTransfer.controls['nonOptionalFee'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Optional Fee:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="optionalFeePopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" formControlName="optionalFee" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" (ngModelChange)="_optionalFee()"
               [class.error]="(isFailedForm ||formTenantTransfer.get('optionalFee')!.touched) && (formTenantTransfer.controls['optionalFee'].value === null || formTenantTransfer.controls['optionalFee'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('optionalFee')!.touched) && (formTenantTransfer.controls['optionalFee'].value === null || formTenantTransfer.controls['optionalFee'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-4 required">
        <div style="height:72px">
          <label class="control-label">Optional Fee Type:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="optionalFeeTypePopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" formControlName="optionalFeeType" type="text"
               [class.error]="(isFailedForm ||formTenantTransfer.get('optionalFeeType')!.touched) && (formTenantTransfer.controls['optionalFeeType'].value === null || formTenantTransfer.controls['optionalFeeType'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('optionalFeeType')!.touched) && (formTenantTransfer.controls['optionalFeeType'].value === null || formTenantTransfer.controls['optionalFeeType'].value === '')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formTenantTransfer.get('optionalFeeType')!.touched) && formTenantTransfer.get('optionalFeeType')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Gross Rent:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="TenGrossRentPopover"
                                                                                      triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" id='tenantGrossRent' disabled type="number" />
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Max Rent:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateTenantTransferCalculations()" formControlName="maximumRent" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
              [class.error]="(isFailedForm ||formTenantTransfer.get('maximumRent')!.touched) && (formTenantTransfer.controls['maximumRent'].value === null || formTenantTransfer.controls['maximumRent'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formTenantTransfer.get('maximumRent')!.touched) && (formTenantTransfer.controls['maximumRent'].value === null || formTenantTransfer.controls['maximumRent'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Rent Qualified:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="TenRentQualifiedPopover"
                                                                                          triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" id='tenantRentQualified' disabled type="text" />
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Overall Tenant Eligible:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="OverallTenEligiblePopover"
                                                                                            triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" id='overallTenantEligible' disabled type="text" />
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Accessible Type:</label>
        </div>
        <select class="form-control" formControlName="accessibleTypeID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option value="">-</option>
          <option *ngFor="let aType of accessibleTypes" [ngValue]="aType.accessibleTypeID">{{aType.accessibleTypeName}}</option>
        </select>
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Senior Type:</label>
        </div>
        <select class="form-control" formControlName="seniorTypeID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option value="">N/A</option>
          <option *ngFor="let sType of seniorTypes" [ngValue]="sType.seniorTypeID">{{sType.seniorTypeName}}</option>
        </select>
      </div>
      <div class="col-md-12">
        <hr>
      </div>
      <div class="col-md-12">
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="previouslyTaxCreditQualified" type="checkbox" formControlName="previouslyTaxCreditQualified" (ngModelChange)="prevTaxCredQualifiedChecked()" />
            <label class="custom-control-label d-block" for="previouslyTaxCreditQualified">Prev. TC Qualified:</label>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- NORMAL FORM -->
  <form [formGroup]="form"  class="no-highlight" *ngIf="formType == 'Normal' || formType == 'NormalPrevQual'">
    <div class="form-row" *ngIf="isAdmin">
      <!-- BFS: adding 3 new fields 1.08 11.11.2020 -->
      <div class="form-group col-md-2">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="unitFileReceived" type="checkbox" formControlName="unitFileReceived"/>
          <label class="custom-control-label d-block" for="unitFileReceived">File Rcvd:</label>
        </div>
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="unitCorrectionReceived" type="checkbox" formControlName="unitCorrectionReceived"/>
          <label class="custom-control-label d-block" for="unitCorrectionReceived">Correction Rcvd:</label>
        </div>
        <div class="col-md-12" style="padding-left: 0px; padding-right: 0px; top: 15px;">
          <label class="control-label">File Rating:</label>
          <select class="form-control" formControlName="fileRatingID">
            <option value="">-</option>
            <option *ngFor="let frType of fileRatingTypes" [ngValue]="frType.fileRatingID">{{frType.fileRatingName}}</option>
          </select>
        </div>
      </div>
      <div class="form-group col-md-10">
          <label class="control-label">Unit Comments:</label>
          <textarea style="resize: none;" rows="4" placeholder="Unit Comments" class="form-control" formControlName="unitComments" type="text"></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-3 form-group required" style="padding-bottom: 5px;">
        <label class="control-label">Building:</label>
        <select class="form-control" formControlName="buildingID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option *ngFor="let building of buildings" [ngValue]="building.buildingID">{{building.buildingNumber}}</option>
        </select>
      </div>
      <div class="col-md-3 form-group" style="padding-bottom: 5px;" *ngIf="isAdmin">
        <label class="control-label">Qualification Override:</label>
        <select class="form-control" formControlName="qualificationOverride">
          <option value=""></option>
          <option value="Include">Include</option>
          <option value="Exclude">Exclude</option>
        </select>
      </div>
    </div>
    <!--row 1 property and user name-->
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">First Year Tenant Name:</label><i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="firstYearTenantPopover"
                                                                       triggers="mouseenter:mouseleave" placement="bottom"></i>
        <input class="form-control" placeholder="Last, First" pattern="[A-Za-z-]+(\, ){1}[A-Za-z-]+" formControlName="firstYearTenantName" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
             [class.error]="(isFailedForm ||form.get('firstYearTenantName')!.touched) && form.controls['firstYearTenantName'].value === ''"  />
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('firstYearTenantName')!.touched) && form.controls['firstYearTenantName'].value === ''">
          This field is required
        </span>
        <span class="error-text"
        *ngIf="(form.controls['firstYearTenantName'].value !== '') && form.get('firstYearTenantName')!.hasError('pattern')">
            Invalid Name Format (Last, First)
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Unit #:</label>
        <input class="form-control" (ngModelChange)="_updateCalculations()" type="text" formControlName="unitNumber" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('unitNumber')!.touched) && (form.controls['unitNumber'].value === null || form.controls['unitNumber'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('unitNumber')!.touched) && (form.controls['unitNumber'].value === null || form.controls['unitNumber'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Move-In Date:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopover"
                                                                       triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (ngModelChange)="_updateMoveOutLimit()" (click)="d1.toggle()" formControlName="moveInDate" name="dp" [disabled]="form3.controls['unitLocked'].value"
                 [class.error]="(isFailedForm ||form.get('moveInDate')!.touched) && (form.get('moveInDate')!.hasError('dateInvalid') || (form.controls['moveInDate'].value === null || form.controls['moveInDate'].value === ''))"
                 [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                 ngbDatepicker #d1="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button" [disabled]="form3.controls['unitLocked'].value"></button>
          </div>
        </div>
        <span class="error-text"
        *ngIf="(isFailedForm ||form.get('moveInDate')!.touched) && (form.controls['moveInDate'].value === null || form.controls['moveInDate'].value === '')">
    This field is required
  </span>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('moveInDate')!.touched) && form.get('moveInDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Move-Out Date:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopoverMoveOut"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (ngModelChange)="_updateCalculations()" (click)="d2.toggle()" formControlName="moveOutDate" name="dp" [disabled]="form3.controls['unitLocked'].value"
                 [class.error]="form.get('moveOutDate')!.hasError('dateInvalid')"
                 [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                 ngbDatepicker [minDate]="minEndDate" #d2="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" [disabled]="form3.controls['unitLocked'].value" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('moveOutDate')!.touched) && form.get('moveOutDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
    </div>
    <hr>
    <!--row 2 startdate and enddate-->
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">Certification Effect Date:</label><i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="certEffDatePopover"
                                                                          triggers="mouseenter:mouseleave" placement="top"></i>
        <div class="input-group">
          <input class="form-control" (ngModelChange)="_updateCalculations()" (click)="d3.toggle()" formControlName="certEffectDate" name="dp" [disabled]="form3.controls['unitLocked'].value"
          [class.error]="(isFailedForm ||form.get('certEffectDate')!.touched) && (form.get('certEffectDate')!.hasError('dateInvalid') || (form.controls['certEffectDate'].value === null || form.controls['certEffectDate'].value === ''))"
          [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
          ngbDatepicker #d3="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" [disabled]="form3.controls['unitLocked'].value" (click)="d3.toggle()" type="button"></button>
          </div>
        </div>
        <span class="error-text"
        *ngIf="(isFailedForm ||form.get('certEffectDate')!.touched) && (form.controls['certEffectDate'].value === null || form.controls['certEffectDate'].value === '')">
    This field is required
  </span>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('certEffectDate')!.touched) && form.get('certEffectDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Temporary Date Vacated:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopoverMoveOut"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (ngModelChange)="_updateCalculations()" formControlName="tempDateVacated" (click)="d4.toggle()" name="dp" [disabled]="form3.controls['unitLocked'].value"
                 [class.error]="form.get('tempDateVacated')!.hasError('dateInvalid')"
                 [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                 ngbDatepicker #d4="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" [disabled]="form3.controls['unitLocked'].value" (click)="d4.toggle()" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('tempDateVacated')!.touched) && form.get('tempDateVacated')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Temporary Date Returned:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopoverMoveOut"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (ngModelChange)="_updateCalculations()" formControlName="tempDateReturned" (click)="d5.toggle()" name="dp" [disabled]="form3.controls['unitLocked'].value"
                 [class.error]="form.get('tempDateReturned')!.hasError('dateInvalid')"
                 [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                 ngbDatepicker #d5="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" [disabled]="form3.controls['unitLocked'].value" (click)="d5.toggle()" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('tempDateReturned')!.touched) && form.get('tempDateReturned')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <div>
          <label class="control-label">Unit Set-Aside:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateCalculations()" formControlName="unitSetAside" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('unitSetAside')!.touched) && (form.controls['unitSetAside'].value === null || form.controls['unitSetAside'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('unitSetAside')!.touched) && (form.controls['unitSetAside'].value === null || form.controls['unitSetAside'].value === '')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||form.get('unitSetAside')!.touched) && form.get('unitSetAside')!.hasError('min')">
          Set Aside Value cannot be negative or zero\
      </span>
      </div>
    </div>
    <hr>
    <!--row 3 the Units-->
    <div class="form-row">
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label">Square Feet:</label>
        </div>
        <input class="form-control" type="number" formControlName="squareFeet" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('squareFeet')!.touched) && (form.controls['squareFeet'].value === null || form.controls['squareFeet'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('squareFeet')!.touched) && (form.controls['squareFeet'].value === null || form.controls['squareFeet'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Bedrooms:</label>
        </div>
        <input class="form-control" type="number" formControlName="numOfBdrms" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('numOfBdrms')!.touched) && (form.controls['numOfBdrms'].value === null || form.controls['numOfBdrms'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('numOfBdrms')!.touched) && (form.controls['numOfBdrms'].value === null || form.controls['numOfBdrms'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-8">

      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label">Total # of Household:</label>
        </div>
      <input class="form-control" type="number" formControlName="totalTenants" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('totalTenants')!.touched) && (form.controls['totalTenants'].value === null || form.controls['totalTenants'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('totalTenants')!.touched) && (form.controls['totalTenants'].value === null || form.controls['totalTenants'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Minors:</label>
        </div>
        <input class="form-control" type="number" formControlName="numOfMinors" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('numOfMinors')!.touched) && (form.controls['numOfMinors'].value === null || form.controls['numOfMinors'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('numOfMinors')!.touched) && (form.controls['numOfMinors'].value === null || form.controls['numOfMinors'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Seniors:</label>        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="numOfSeniorsPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" type="number" formControlName="numOfSeniors" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('numOfSeniors')!.touched) && (form.controls['numOfSeniors'].value === null || form.controls['numOfSeniors'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('numOfSeniors')!.touched) && (form.controls['numOfSeniors'].value === null || form.controls['numOfSeniors'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Veterans:</label>
        </div>
        <input class="form-control" type="number" formControlName="numOfVeterans" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('numOfVeterans')!.touched) && (form.controls['numOfVeterans'].value === null || form.controls['numOfVeterans'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('numOfVeterans')!.touched) && (form.controls['numOfVeterans'].value === null || form.controls['numOfVeterans'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:56px;">
          <label class="control-label"># of Students:</label>
        </div>
        <input class="form-control" type="number" formControlName="numOfStudents" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('numOfStudents')!.touched) && (form.controls['numOfStudents'].value === null || form.controls['numOfStudents'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('numOfStudents')!.touched) && (form.controls['numOfStudents'].value === null || form.controls['numOfStudents'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="allFullTimeStudents" type="checkbox" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="allFullTimeStudents" />
          <label class="custom-control-label d-block" for="allFullTimeStudents">All Full-Time Students:</label>
        </div>
      </div>
    </div>
    <hr>
    <!--row 4 vacant-->
    <div class="form-row">
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Gross Income at Move-In:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateCalculations()" type="number" formControlName="grossIncome" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('grossIncome')!.touched) && (form.controls['grossIncome'].value === null || form.controls['grossIncome'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('grossIncome')!.touched) && (form.controls['grossIncome'].value === null || form.controls['grossIncome'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Income Limits at Move-In:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateCalculations()" formControlName="moveInIncomeLimits" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('moveInIncomeLimits')!.touched) && (form.controls['moveInIncomeLimits'].value === null || form.controls['moveInIncomeLimits'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('moveInIncomeLimits')!.touched) && (form.controls['moveInIncomeLimits'].value === null || form.controls['moveInIncomeLimits'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Income Qualified:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="TenIncomeQualifiedPopover"
                                                                                             triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <input class="form-control" id='tenantIncomeQualified' disabled type="text" />
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Tenant Payment:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateCalculations()" formControlName="unitRent" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('unitRent')!.touched) && (form.controls['unitRent'].value === null || form.controls['unitRent'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('unitRent')!.touched) && (form.controls['unitRent'].value === null || form.controls['unitRent'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Rent Subsidy:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="rentSubsidyPopover"
          triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <input class="form-control" (ngModelChange)="_updateCalculations()" formControlName="rentSubsidy" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('rentSubsidy')!.touched) && (form.controls['rentSubsidy'].value === null || form.controls['rentSubsidy'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('rentSubsidy')!.touched) && (form.controls['rentSubsidy'].value === null || form.controls['rentSubsidy'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">U/A:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateCalculations()" formControlName="utilityAllowance" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('utilityAllowance')!.touched) && (form.controls['utilityAllowance'].value === null || form.controls['utilityAllowance'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('utilityAllowance')!.touched) && (form.controls['utilityAllowance'].value === null || form.controls['utilityAllowance'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Non Optional Fee:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="nonOptionalFeePopover"
          triggers="mouseenter:mouseleave" placement="right"></i>

        </div>
        <input class="form-control" (ngModelChange)="_updateCalculations()" formControlName="nonOptionalFee" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('nonOptionalFee')!.touched) && (form.controls['nonOptionalFee'].value === null || form.controls['nonOptionalFee'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('nonOptionalFee')!.touched) && (form.controls['nonOptionalFee'].value === null || form.controls['nonOptionalFee'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Optional Fee:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="optionalFeePopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" formControlName="optionalFee" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" (ngModelChange)="_optionalFee()"
               [class.error]="(isFailedForm ||form.get('optionalFee')!.touched) && (form.controls['optionalFee'].value === null || form.controls['optionalFee'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('optionalFee')!.touched) && (form.controls['optionalFee'].value === null || form.controls['optionalFee'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-4 required">
        <div style="height:72px">
          <label class="control-label">Optional Fee Type:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="optionalFeeTypePopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" formControlName="optionalFeeType" type="text"
               [class.error]="(isFailedForm ||form.get('optionalFeeType')!.touched) && (form.controls['optionalFeeType'].value === null || form.controls['optionalFeeType'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('optionalFeeType')!.touched) && (form.controls['optionalFeeType'].value === null || form.controls['optionalFeeType'].value === '')">
          This field is required
        </span>
        <span class="error-text" 
              *ngIf="(isFailedForm ||form.get('optionalFeeType')!.touched) && form.get('optionalFeeType')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Gross Rent:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="TenGrossRentPopover"
                                                                                       triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" id='tenantGrossRent' disabled type="number" />
      </div>
      <div class="form-group col-md-2 required">
        <div style="height:72px">
          <label class="control-label">Max Rent:</label>
        </div>
        <input class="form-control" (ngModelChange)="_updateCalculations()" formControlName="maximumRent" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||form.get('maximumRent')!.touched) && (form.controls['maximumRent'].value === null || form.controls['maximumRent'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('maximumRent')!.touched) && (form.controls['maximumRent'].value === null || form.controls['maximumRent'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Rent Qualified:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="TenRentQualifiedPopover"
                                                                                           triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" id='tenantRentQualified' disabled type="text" />
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Overall Tenant Eligible:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="OverallTenEligiblePopover"
                                                                                             triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <input class="form-control" id='overallTenantEligible' disabled type="text" />
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Accessible Type:</label>
        </div>
        <select class="form-control" formControlName="accessibleTypeID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option value="">-</option>
          <option *ngFor="let aType of accessibleTypes" [ngValue]="aType.accessibleTypeID">{{aType.accessibleTypeName}}</option>
        </select>
      </div>
      <div class="form-group col-md-2">
        <div style="height:72px">
          <label class="control-label" style="width:80%;">Senior Type:</label>
        </div>
        <select class="form-control" formControlName="seniorTypeID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option value="">N/A</option>
          <option *ngFor="let sType of seniorTypes" [ngValue]="sType.seniorTypeID">{{sType.seniorTypeName}}</option>
        </select>
      </div>
      <div class="col-md-12">
        <hr>
      </div>
      <div class="col-md-12">
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="previouslyTaxCreditQualified" type="checkbox" formControlName="previouslyTaxCreditQualified" (ngModelChange)="prevTaxCredQualifiedChecked()" />
            <label class="custom-control-label d-block" for="previouslyTaxCreditQualified">Prev. TC Qualified:</label>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="formPreviouslyTaxCreditQualified"  class="no-highlight" *ngIf="formType == 'NormalPrevQual' || formType == 'TransferPrevQual'">
    <!--row 1 property and user name-->
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">Prev. Certification Effect Date:</label>
        <div class="input-group">
          <input class="form-control" formControlName="previousCertEffectDate" name="dp" (click)="d6.toggle()" [disabled]="form3.controls['unitLocked'].value" (ngModelChange)="_prevTaxCredQualifiedCheckedCalculations()"
          [class.error]="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousCertEffectDate')!.touched) && (formPreviouslyTaxCreditQualified.get('previousCertEffectDate')!.hasError('dateInvalid') || (formPreviouslyTaxCreditQualified.controls['previousCertEffectDate'].value === null || formPreviouslyTaxCreditQualified.controls['previousCertEffectDate'].value === ''))"    
          [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"      
          ngbDatepicker #d6="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" [disabled]="form3.controls['unitLocked'].value" (click)="d6.toggle()" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousCertEffectDate')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousCertEffectDate'].value === null || formPreviouslyTaxCreditQualified.controls['previousCertEffectDate'].value === '')">
          This field is required
        </span>
        <span class="error-text"
              *ngIf="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousCertEffectDate')!.touched) && formPreviouslyTaxCreditQualified.get('previousCertEffectDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Prev. Total # of Household:</label>
        <input class="form-control" type="number" formControlName="previousTotalTenants" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" (ngModelChange)="_prevTaxCredQualifiedCheckedCalculations()"
        [class.error]="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousTotalTenants')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousTotalTenants'].value === null || formPreviouslyTaxCreditQualified.controls['previousTotalTenants'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousTotalTenants')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousTotalTenants'].value === null || formPreviouslyTaxCreditQualified.controls['previousTotalTenants'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
          <label class="control-label">Prev. Gross Income at Move-In:</label>
        <input class="form-control" (ngModelChange)="_prevTaxCredQualifiedCheckedCalculations()" type="number" formControlName="previousGrossIncome" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
        [class.error]="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousGrossIncome')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousGrossIncome'].value === null || formPreviouslyTaxCreditQualified.controls['previousGrossIncome'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousGrossIncome')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousGrossIncome'].value === null || formPreviouslyTaxCreditQualified.controls['previousGrossIncome'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
          <label class="control-label">Prev. Income Limits at Move-In:</label>
        <input class="form-control" (ngModelChange)="_prevTaxCredQualifiedCheckedCalculations()" formControlName="previousMoveInIncomeLimits" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
        [class.error]="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousMoveInIncomeLimits')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousMoveInIncomeLimits'].value === null || formPreviouslyTaxCreditQualified.controls['previousMoveInIncomeLimits'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousMoveInIncomeLimits')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousMoveInIncomeLimits'].value === null || formPreviouslyTaxCreditQualified.controls['previousMoveInIncomeLimits'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
          <label class="control-label">Prev. Tenant Payment:</label>
        <input class="form-control" (ngModelChange)="_prevTaxCredQualifiedCheckedCalculations()" formControlName="previousUnitRent" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
        [class.error]="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousUnitRent')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value === null || formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousUnitRent')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value === null || formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
          <label class="control-label">Prev. Rent Subsidy:</label>
        <input class="form-control" (ngModelChange)="_prevTaxCredQualifiedCheckedCalculations()" formControlName="previousRentSubsidy" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" 
        [class.error]="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousRentSubsidy')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousRentSubsidy'].value === null || formPreviouslyTaxCreditQualified.controls['previousRentSubsidy'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousRentSubsidy')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousRentSubsidy'].value === null || formPreviouslyTaxCreditQualified.controls['previousRentSubsidy'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
          <label class="control-label">Prev. U/A:</label>
        <input class="form-control" (ngModelChange)="_prevTaxCredQualifiedCheckedCalculations()" formControlName="previousUtilityAllowance" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
        [class.error]="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousUtilityAllowance')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousUtilityAllowance'].value === null || formPreviouslyTaxCreditQualified.controls['previousUtilityAllowance'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousUtilityAllowance')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousUtilityAllowance'].value === null || formPreviouslyTaxCreditQualified.controls['previousUtilityAllowance'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
          <label class="control-label">Prev. Max Rent:</label>
        <input class="form-control" (ngModelChange)="_prevTaxCredQualifiedCheckedCalculations()" formControlName="previousMaximumRent" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
        [class.error]="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousMaximumRent')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousMaximumRent'].value === null || formPreviouslyTaxCreditQualified.controls['previousMaximumRent'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formPreviouslyTaxCreditQualified.get('previousMaximumRent')!.touched) && (formPreviouslyTaxCreditQualified.controls['previousMaximumRent'].value === null || formPreviouslyTaxCreditQualified.controls['previousMaximumRent'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3">
          <label class="control-label">Prev. Income Qualified:</label>
        <input class="form-control" id='tenantIncomeQualifiedPrevious' disabled type="text" />
      </div>
      <div class="form-group col-md-3">
          <label class="control-label">Prev. Gross Rent:</label>
        <input class="form-control" id='tenantGrossRentPrevious' disabled type="number" />
      </div>
      <div class="form-group col-md-3">
          <label class="control-label">Prev. Rent Qualified:</label>
        <input class="form-control" id='tenantRentQualifiedPrevious' disabled type="text" />
      </div>
      <div class="form-group col-md-3">
          <label class="control-label">Prev. Overall Tenant Eligible:</label>
        <input class="form-control" id='overallTenantEligiblePrevious' disabled type="text" />
      </div>
    </div>
  </form>
</div>

<!-- IF THE MAIN BODY IS EITHER CHECKED WITH EXEMPT, MARKET OR VACANT -->
<div class="modal-body" *ngIf='isFormTypeExempt() || isFormTypeMarket() || isFormTypeVacant() || isFormTypeNonQualifiedOccupied() ' style="overflow:visible">
  <form [formGroup]="formExemptTop"  class="no-highlight">
    <div class="form-row">
      <div class="form-group col-md-12 row" *ngIf='isFormTypeNormal() || isFormTypeNormalPrevQual() || isFormTypeExempt() '>
        <div class="form-group col-md-3">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="exemptUnit" type="checkbox" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="exemptUnit" (ngModelChange)="exemptUnitChecked()" />
            <label class="custom-control-label d-block" for="exemptUnit">Exempt Unit:<i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="exemptUnitPopover"
              triggers="mouseenter:mouseleave" placement="bottom"></i></label>
          </div>
        </div>
        <div class="form-group col-md-9" *ngIf="formType == 'Exempt'">
          <label class="control-label">Additional Information:<i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="exemptUnitCommentPopover"
            triggers="mouseenter:mouseleave" placement="bottom"></i></label>
          <input class="form-control" formControlName="exemptUnitComment" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"/>
        </div>
      </div>
      <div class="form-group col-md-12 row" *ngIf="(formType == 'Normal') || (formType == 'NormalPrevQual') || (formType == 'Market')">
        <div class="form-group col-md-3">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="marketUnit" type="checkbox"  [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="marketUnit" (ngModelChange)="marketUnitChecked()" />
            <label class="custom-control-label d-block" for="marketUnit">Market Unit:</label>
          </div>
        </div>
      </div>
      <div class="form-group col-md-12 row" *ngIf="(formType == 'Normal') || (formType == 'NormalPrevQual') || (formType == 'Transfer') || (formType == 'TransferPrevQual')">
        <div class="form-group col-md-3">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="tenantTransfer" type="checkbox"  [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" formControlName="tenantTransfer" (ngModelChange)="tenantTransferChecked()" />
            <label class="custom-control-label d-block" for="tenantTransfer">Tenant Transfer:<i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="tenantTransferPopover"
              triggers="mouseenter:mouseleave" placement="bottom"></i></label>
          </div>
        </div>
      </div>

    </div>
  </form>
  <form [formGroup]="formExempt" class="no-highlight" *ngIf="formType == 'Exempt'">
    <div class="form-row">
      <div class="col-md-3 form-group required" style="padding-bottom: 5px;">
        <label class="control-label">Building:</label>
        <select class="form-control" formControlName="buildingID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option *ngFor="let building of buildings" [ngValue]="building.buildingID">{{building.buildingNumber}}</option>
        </select>
        <span class="error-text"
        *ngIf="(isFailedForm ||formExempt.get('buildingID')!.touched) && (formExempt.controls['buildingID'].value === '')">
          This field is required
        </span>
      </div>
      <div class="col-md-3 form-group" style="padding-bottom: 5px;" *ngIf="isAdmin">
        <label class="control-label">Qualification Override:</label>
        <select class="form-control" formControlName="qualificationOverride">
          <option value=""></option>
          <option value="Include">Include</option>
          <option value="Exclude">Exclude</option>
        </select>
      </div>

    </div>
    <!--row 1 property and user name-->
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">First Year Tenant Name:</label><i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="firstYearTenantPopover"
                                                                       triggers="mouseenter:mouseleave" placement="bottom"></i>
        <input class="form-control" placeholder="Last, First" pattern="[A-Za-z-]+(\, ){1}[A-Za-z-]+" formControlName="firstYearTenantName" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
             [class.error]="(isFailedForm ||formExempt.get('firstYearTenantName')!.touched) && formExempt.controls['firstYearTenantName'].value === ''"  />
        <span class="error-text"
              *ngIf="(isFailedForm ||formExempt.get('firstYearTenantName')!.touched) && formExempt.controls['firstYearTenantName'].value === ''">
          This field is required
        </span>
        <span class="error-text"
        *ngIf="(formExempt.controls['firstYearTenantName'].value !== '') && formExempt.get('firstYearTenantName')!.hasError('pattern')">
            Invalid Name Format (Last, First)
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Unit #:</label>
        <input class="form-control" type="text" formControlName="unitNumber" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formExempt.get('unitNumber')!.touched) && (formExempt.controls['unitNumber'].value === null || formExempt.controls['unitNumber'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formExempt.get('unitNumber')!.touched) && (formExempt.controls['unitNumber'].value === null || formExempt.controls['unitNumber'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Move-In Date:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopover"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (ngModelChange)="_updateMoveOutLimit()" (click)="d1.toggle()" formControlName="moveInDate" name="dp" [disabled]="form3.controls['unitLocked'].value"
                 [class.error]="(isFailedForm ||formExempt.get('moveInDate')!.touched) && (formExempt.get('moveInDate')!.hasError('dateInvalid') || (formExempt.controls['moveInDate'].value === null || formExempt.controls['moveInDate'].value === ''))"
                 [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                 ngbDatepicker #d1="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button" [disabled]="form3.controls['unitLocked'].value"></button>
          </div>
        </div>
        <span class="error-text"
        *ngIf="(isFailedForm ||formExempt.get('moveInDate')!.touched) && (formExempt.controls['moveInDate'].value === null || formExempt.controls['moveInDate'].value === '')">
          This field is required
        </span>
        <span class="error-text"
              *ngIf="(isFailedForm ||formExempt.get('moveInDate')!.touched) && formExempt.get('moveInDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Move-Out Date:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopoverMoveOut"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" formControlName="moveOutDate" (click)="d2.toggle()"  name="dp" [disabled]="form3.controls['unitLocked'].value"
                 [class.error]="formExempt.get('moveOutDate')!.hasError('dateInvalid')"
                 [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                 ngbDatepicker [minDate]="minEndDate" #d2="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" [disabled]="form3.controls['unitLocked'].value" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||formExempt.get('moveOutDate')!.touched) && formExempt.get('moveOutDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label"># of Bedrooms:</label>
        <input class="form-control" type="number" formControlName="numOfBdrms" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formExempt.get('numOfBdrms')!.touched) && (formExempt.controls['numOfBdrms'].value === null || formExempt.controls['numOfBdrms'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formExempt.get('numOfBdrms')!.touched) && (formExempt.controls['numOfBdrms'].value === null || formExempt.controls['numOfBdrms'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Square Feet:</label>
        <input class="form-control" type="number" formControlName="squareFeet" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formExempt.get('squareFeet')!.touched) && (formExempt.controls['squareFeet'].value === null || formExempt.controls['squareFeet'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formExempt.get('squareFeet')!.touched) && (formExempt.controls['squareFeet'].value === null || formExempt.controls['squareFeet'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-6 required">

      </div>
      <div class="form-group col-md-3 required">
        <div style="height:72px">
          <label class="control-label">Non Optional Fee:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="nonOptionalFeePopover"
          triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <input class="form-control" formControlName="nonOptionalFee" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formExempt.get('nonOptionalFee')!.touched) && (formExempt.controls['nonOptionalFee'].value === null || formExempt.controls['nonOptionalFee'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formExempt.get('nonOptionalFee')!.touched) && (formExempt.controls['nonOptionalFee'].value === null || formExempt.controls['nonOptionalFee'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <div style="height:72px">
          <label class="control-label">Optional Fee:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="optionalFeePopover"
          triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <input class="form-control" formControlName="optionalFee" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" (ngModelChange)="_optionalFee()"
               [class.error]="(isFailedForm ||formExempt.get('optionalFee')!.touched) && (formExempt.controls['optionalFee'].value === null || formExempt.controls['optionalFee'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formExempt.get('optionalFee')!.touched) && (formExempt.controls['optionalFee'].value === null || formExempt.controls['optionalFee'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-6 required">
        <div style="height:72px">
          <label class="control-label">Optional Fee Type:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="optionalFeeTypePopover"
          triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <input class="form-control" formControlName="optionalFeeType" type="text"
               [class.error]="(isFailedForm ||formExempt.get('optionalFeeType')!.touched) && (formExempt.controls['optionalFeeType'].value === null || formExempt.controls['optionalFeeType'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formExempt.get('optionalFeeType')!.touched) && (formExempt.controls['optionalFeeType'].value === null || formExempt.controls['optionalFeeType'].value === '')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formExempt.get('optionalFeeType')!.touched) && formExempt.get('optionalFeeType')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
    </div>
  </form>
  <form [formGroup]="formMarket" class="no-highlight" *ngIf="formType == 'Market'">
    <div class="form-row">
      <div class="col-md-3 form-group required" style="padding-bottom: 5px;">
        <label class="control-label">Building:</label>
        <select class="form-control" formControlName="buildingID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option *ngFor="let building of buildings" [ngValue]="building.buildingID">{{building.buildingNumber}}</option>
        </select>
      </div>
      <div class="col-md-3 form-group" style="padding-bottom: 5px;" *ngIf="isAdmin">
        <label class="control-label">Qualification Override:</label>
        <select class="form-control" formControlName="qualificationOverride">
          <option value=""></option>
          <option value="Include">Include</option>
          <option value="Exclude">Exclude</option>
        </select>
      </div>
    </div>
    <!--row 1 property and user name-->
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">First Year Tenant Name:</label><i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="firstYearTenantPopover"
                                                                       triggers="mouseenter:mouseleave" placement="bottom"></i>
        <input class="form-control" placeholder="Last, First" pattern="[A-Za-z-]+(\, ){1}[A-Za-z-]+" formControlName="firstYearTenantName" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
             [class.error]="(isFailedForm ||formMarket.get('firstYearTenantName')!.touched) && formMarket.controls['firstYearTenantName'].value === ''"  />
        <span class="error-text"
              *ngIf="(isFailedForm ||formMarket.get('firstYearTenantName')!.touched) && formMarket.controls['firstYearTenantName'].value === ''">
          This field is required
        </span>
        <span class="error-text"
        *ngIf="(formMarket.controls['firstYearTenantName'].value !== '') && formMarket.get('firstYearTenantName')!.hasError('pattern')">
            Invalid Name Format (Last, First)
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Unit #:</label>
        <input class="form-control" type="text" formControlName="unitNumber" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formMarket.get('unitNumber')!.touched) && (formMarket.controls['unitNumber'].value === null || formMarket.controls['unitNumber'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formMarket.get('unitNumber')!.touched) && (formMarket.controls['unitNumber'].value === null || formMarket.controls['unitNumber'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Move-In Date:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopover"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (ngModelChange)="_updateMoveOutLimit()" (click)="d1.toggle()" formControlName="moveInDate" name="dp" [disabled]="form3.controls['unitLocked'].value"
                 [class.error]="(isFailedForm ||formMarket.get('moveInDate')!.touched) && (formMarket.get('moveInDate')!.hasError('dateInvalid') || (formMarket.controls['moveInDate'].value === null || formMarket.controls['moveInDate'].value === ''))"
                 [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                 ngbDatepicker #d1="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button" [disabled]="form3.controls['unitLocked'].value"></button>
          </div>
        </div>
        <span class="error-text"
        *ngIf="(isFailedForm ||formMarket.get('moveInDate')!.touched) && (formMarket.controls['moveInDate'].value === null || formMarket.controls['moveInDate'].value === '')">
          This field is required
        </span>
        <span class="error-text"
              *ngIf="(isFailedForm ||formMarket.get('moveInDate')!.touched) && formMarket.get('moveInDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Move-Out Date:</label>
        <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="datePopoverMoveOut"
        triggers="mouseenter:mouseleave" placement="bottom"></i>
        <div class="input-group">
          <input class="form-control" (click)="d2.toggle()" formControlName="moveOutDate" name="dp" [disabled]="form3.controls['unitLocked'].value"
                 [class.error]="formMarket.get('moveOutDate')!.hasError('dateInvalid')"
                 [maxDate]="{year: 2050, month: 1, day: 1}"
                 ngbDatepicker [minDate]="minEndDate" #d2="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" [disabled]="form3.controls['unitLocked'].value" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||formMarket.get('moveOutDate')!.touched) && formMarket.get('moveOutDate')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label"># of Bedrooms:</label>
        <input class="form-control" type="number" formControlName="numOfBdrms" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formMarket.get('numOfBdrms')!.touched) && (formMarket.controls['numOfBdrms'].value === null || formMarket.controls['numOfBdrms'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formMarket.get('numOfBdrms')!.touched) && (formMarket.controls['numOfBdrms'].value === null || formMarket.controls['numOfBdrms'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Square Feet:</label>
        <input class="form-control" type="number" formControlName="squareFeet" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formMarket.get('squareFeet')!.touched) && (formMarket.controls['squareFeet'].value === null || formMarket.controls['squareFeet'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formMarket.get('squareFeet')!.touched) && (formMarket.controls['squareFeet'].value === null || formMarket.controls['squareFeet'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-6 required">

      </div>
      <div class="form-group col-md-3 required">
        <div style="height:72px">
          <label class="control-label">Non Optional Fee:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="nonOptionalFeePopover"
          triggers="mouseenter:mouseleave" placement="right"></i>

        </div>
        <input class="form-control" formControlName="nonOptionalFee" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formMarket.get('nonOptionalFee')!.touched) && (formMarket.controls['nonOptionalFee'].value === null || formMarket.controls['nonOptionalFee'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formMarket.get('nonOptionalFee')!.touched) && (formMarket.controls['nonOptionalFee'].value === null || formMarket.controls['nonOptionalFee'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <div style="height:72px">
          <label class="control-label">Optional Fee:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="optionalFeePopover"
          triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <input class="form-control" formControlName="optionalFee" type="number" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" (ngModelChange)="_optionalFee()"
               [class.error]="(isFailedForm ||formMarket.get('optionalFee')!.touched) && (formMarket.controls['optionalFee'].value === null || formMarket.controls['optionalFee'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formMarket.get('optionalFee')!.touched) && (formMarket.controls['optionalFee'].value === null || formMarket.controls['optionalFee'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-6 required">
        <div style="height:72px">
          <label class="control-label">Optional Fee Type:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="optionalFeeTypePopover"
          triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <input class="form-control" formControlName="optionalFeeType" type="text"
               [class.error]="(isFailedForm ||formMarket.get('optionalFeeType')!.touched) && (formMarket.controls['optionalFeeType'].value === null || formMarket.controls['optionalFeeType'].value === '')"/>
        <span class="error-text"
              *ngIf="(isFailedForm ||formMarket.get('optionalFeeType')!.touched) && (formMarket.controls['optionalFeeType'].value === null || formMarket.controls['optionalFeeType'].value === '')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formMarket.get('optionalFeeType')!.touched) && formMarket.get('optionalFeeType')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <div class="form-group col-md-12" *ngIf="isAdmin">
        <label class="control-label">Market Unit Comment:</label>
        <textarea style="resize: none;" rows="4" placeholder="Market Unit Comment" class="form-control" formControlName="marketUnitComment" type="text"></textarea>
      </div>
    </div>
  </form>
  <form [formGroup]="formVacant" class="no-highlight" *ngIf="formType == 'Vacant'">
    <div class="form-row">
      <div class="col-md-3 form-group required" style="padding-bottom: 5px;">
        <label class="control-label">Building:</label>
        <select class="form-control" formControlName="buildingID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option *ngFor="let building of buildings" [ngValue]="building.buildingID">{{building.buildingNumber}}</option>
        </select>
      </div>
      <div class="col-md-3 form-group" style="padding-bottom: 5px;" *ngIf="isAdmin">
        <label class="control-label">Qualification Override:</label>
        <select class="form-control" formControlName="qualificationOverride">
          <option value=""></option>
          <option value="Include">Include</option>
          <option value="Exclude">Exclude</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">Unit #:</label>
        <input class="form-control" type="text" formControlName="unitNumber" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formVacant.get('unitNumber')!.touched) && (formVacant.controls['unitNumber'].value === null || formVacant.controls['unitNumber'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formVacant.get('unitNumber')!.touched) && (formVacant.controls['unitNumber'].value === null || formVacant.controls['unitNumber'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label"># of Bedrooms:</label>
        <input class="form-control" type="number" formControlName="numOfBdrms" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
        [class.error]="(isFailedForm ||formVacant.get('numOfBdrms')!.touched) && (formVacant.controls['numOfBdrms'].value === null || formVacant.controls['numOfBdrms'].value === '')"/>
        <span class="error-text"
        *ngIf="(isFailedForm ||formVacant.get('numOfBdrms')!.touched) && (formVacant.controls['numOfBdrms'].value === null || formVacant.controls['numOfBdrms'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Square Feet:</label>
        <input class="form-control" type="number" formControlName="squareFeet" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
        [class.error]="(isFailedForm ||formVacant.get('squareFeet')!.touched) && (formVacant.controls['squareFeet'].value === null || formVacant.controls['squareFeet'].value === '')"/>
        <span class="error-text"
        *ngIf="(isFailedForm ||formVacant.get('squareFeet')!.touched) && (formVacant.controls['squareFeet'].value === null || formVacant.controls['squareFeet'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-12" *ngIf="isAdmin">
        <label class="control-label">Vacant Unit Comment:</label>
        <textarea style="resize: none;" rows="4" placeholder="Vacant Unit Comment" class="form-control" formControlName="vacantUnitComment" type="text"></textarea>
      </div>
    </div>
  </form>
  <form [formGroup]="formNonQualifiedOccupied" class="no-highlight" *ngIf="formType == 'NonQualifiedOccupied'">
    <div class="form-row">
      <div class="col-md-3 form-group required" style="padding-bottom: 5px;">
        <label class="control-label">Building:</label>
        <select class="form-control" formControlName="buildingID" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null">
          <option *ngFor="let building of buildings" [ngValue]="building.buildingID">{{building.buildingNumber}}</option>
        </select>
      </div>
      <div class="col-md-3 form-group" style="padding-bottom: 5px;" *ngIf="isAdmin">
        <label class="control-label">Qualification Override:</label>
        <select class="form-control" formControlName="qualificationOverride">
          <option value=""></option>
          <option value="Include">Include</option>
          <option value="Exclude">Exclude</option>
        </select>
      </div>

    </div>
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">Unit #:</label>
        <input class="form-control" type="text" formControlName="unitNumber" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
               [class.error]="(isFailedForm ||formNonQualifiedOccupied.get('unitNumber')!.touched) && (formNonQualifiedOccupied.controls['unitNumber'].value === null || formNonQualifiedOccupied.controls['unitNumber'].value === '')" />
        <span class="error-text"
              *ngIf="(isFailedForm ||formNonQualifiedOccupied.get('unitNumber')!.touched) && (formNonQualifiedOccupied.controls['unitNumber'].value === null || formNonQualifiedOccupied.controls['unitNumber'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label"># of Bedrooms:</label>
        <input class="form-control" type="number" formControlName="numOfBdrms" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null"
        [class.error]="(isFailedForm ||formNonQualifiedOccupied.get('numOfBdrms')!.touched) && (formNonQualifiedOccupied.controls['numOfBdrms'].value === null || formNonQualifiedOccupied.controls['numOfBdrms'].value === '')"/>
        <span class="error-text"
        *ngIf="(isFailedForm ||formNonQualifiedOccupied.get('numOfBdrms')!.touched) && (formNonQualifiedOccupied.controls['numOfBdrms'].value === null || formNonQualifiedOccupied.controls['numOfBdrms'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Square Feet:</label>
        <input class="form-control" type="number" formControlName="squareFeet" [attr.disabled]="form3.controls['unitLocked'].value ? '' : null" 
        [class.error]="(isFailedForm ||formNonQualifiedOccupied.get('squareFeet')!.touched) && (formNonQualifiedOccupied.controls['squareFeet'].value === null || formNonQualifiedOccupied.controls['squareFeet'].value === '')"/>
        <span class="error-text"
        *ngIf="(isFailedForm ||formNonQualifiedOccupied.get('squareFeet')!.touched) && (formNonQualifiedOccupied.controls['squareFeet'].value === null || formNonQualifiedOccupied.controls['squareFeet'].value === '')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-12" *ngIf="isAdmin">
        <label class="control-label">Non-Qualified Occupied Unit Comment:</label>
        <textarea style="resize: none;" rows="4" placeholder="Non-Qualified Unit Comment" class="form-control" formControlName="nonQualifiedOccupiedComment" type="text"></textarea>
      </div>
    </div>
 </form>
</div>

<!-- THESE ARE ALL FOOTERS -->
<div class="modal-footer" *ngIf="formType == 'Normal' || formType == 'NormalPrevQual'">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col"><small>{{form.get("createdByName")!.value}}, {{ form.get("createdDate")!.value | date : "short" }}</small></div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col"><small>{{form.get("updatedByName")!.value}}, {{ form.get("updatedDate")!.value | date : "short" }}</small></div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!form.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
  <!-- <button [disabled]="!formExempt.valid" type="button" class="btn btn-primary" (click)="save()" *ngIf="exemptUnit">Save2</button> -->
</div>

<div class="modal-footer" *ngIf="formType == 'Exempt'">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col"><small>{{formExempt.get("createdByName")!.value}}, {{ formExempt.get("createdDate")!.value | date : "short" }}</small></div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col"><small>{{formExempt.get("updatedByName")!.value}}, {{ formExempt.get("updatedDate")!.value | date : "short" }}</small></div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!formExempt.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>

<div class="modal-footer" *ngIf="formType == 'Market'">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col"><small>{{formMarket.get("createdByName")!.value}}, {{ formMarket.get("createdDate")!.value | date : "short" }}</small></div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col"><small>{{formMarket.get("updatedByName")!.value}}, {{ formMarket.get("updatedDate")!.value | date : "short" }}</small></div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!formMarket.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>

<div class="modal-footer" *ngIf="formType == 'Transfer' || formType == 'TransferPrevQual'">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col"><small>{{formTenantTransfer.get("createdByName")!.value}}, {{ formTenantTransfer.get("createdDate")!.value | date : "short" }}</small></div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col"><small>{{formTenantTransfer.get("updatedByName")!.value}}, {{ formTenantTransfer.get("updatedDate")!.value | date : "short" }}</small></div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!formTenantTransfer.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>

<div class="modal-footer" *ngIf="formType == 'Vacant'">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col"><small>{{formVacant.get("createdByName")!.value}}, {{ formVacant.get("createdDate")!.value | date : "short" }}</small></div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col"><small>{{formVacant.get("updatedByName")!.value}}, {{ formVacant.get("updatedDate")!.value | date : "short" }}</small></div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!formVacant.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>

<div class="modal-footer" *ngIf="formType == 'NonQualifiedOccupied'">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col"><small>{{formNonQualifiedOccupied.get("createdByName")!.value}}, {{ formNonQualifiedOccupied.get("createdDate")!.value | date : "short" }}</small></div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col"><small>{{formNonQualifiedOccupied.get("updatedByName")!.value}}, {{ formNonQualifiedOccupied.get("updatedDate")!.value | date : "short" }}</small></div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!formNonQualifiedOccupied.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>